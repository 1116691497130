/* prettier-ignore */

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: (
  ) !default;
$container-max-widths: map-merge((sm: 540px,
      md: 720px,
      lg: 960px,
      xl: 1140px,
      xxl: 1320px,
    ),
    $container-max-widths
);

$grid-breakpoints: (
  ) !default;
$grid-breakpoints: map-merge((xs: 0,
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1200px,
      xxl: 1400px,
    ),
    $grid-breakpoints
);

//*-----------------------------------------------
//|   Gutter
//-----------------------------------------------*/
$grid-gutter-width: 2rem !default;

//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/

$white: #FFFEFE !default;
$gray-100: #FCFCFC !default;
$gray-200: #F5F5F5 !default;
$gray-300: #EEEEEE !default;
$gray-400: #E0E0E0 !default;
$gray-500: #BDBDBD !default;
$gray-600: #F0F4F9 !default;
$gray-700: #757575 !default;
$gray-800: #616161 !default;
$gray-900: #5E6282 !default;
$gray-1000: #212832 !default;
$black: #000 !default;

$grays: (
  ) !default;
$grays: map-merge(('black': $black,
      '100': $gray-100,
      '200': $gray-200,
      '300': $gray-300,
      '400': $gray-400,
      '500': $gray-500,
      '600': $gray-600,
      '700': $gray-700,
      '800': $gray-800,
      '900': $gray-900,
      '1000': $gray-1000,
      'white': $white,
    ),
    $grays
);

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$blue: #747DEF !default;
$indigo: #6237FF !default;
$purple: #A1B0FC !default;
$pink: #D42DA3 !default;
$red: #DF6951 !default;
$orange: #DF6951 !default;
$yellow: #F1A501 !default;
$green: #79B93C !default;
$teal: #006380 !default;
$cyan: #2fc4f1 !default;
$light: #F0F4F9 !default;
$dark-blue: #14183E !default;
$navy-blue: #181E4B !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $yellow !default;
$secondary: $gray-900 !default;
$success: $green !default;
$info: $teal !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $light !default;
$dark: $gray-1000 !default;

$theme-colors: (
  ) !default;
$theme-colors: map-merge(('primary': $primary,
      'secondary': $secondary,
      'success': $success,
      'info': $info,
      'warning': $warning,
      'danger': $danger,
      'light': $light,
      'dark': $dark,
    ),
    $theme-colors
);
$theme-color-interval: 8% !default;

$theme-colors-soft: (
  ) !default;
$theme-colors-soft: map-merge(('primary': tint-color($primary, 85),
      'secondary': tint-color($secondary, 85),
      'success': tint-color($success, 85),
      'info': tint-color($info, 85),
      'warning': tint-color($warning, 85),
      'danger': tint-color($danger, 85),
      'light': tint-color($light, 85),
      'dark': tint-color($dark, 85),
    ),
    $theme-colors-soft
);

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $gray-800 !default;

// Min contrast ratio
$min-contrast-ratio: 2 !default;

//*-----------------------------------------------
//|   Brand colors
//-----------------------------------------------*/
$linkedin: #0077b5 !default;
$facebook: #3c5a99 !default;
$twitter: #1da1f2 !default;
$google-plus: #dd4b39 !default;
$github: #333 !default;
$youtube: #ff0000 !default;
$instagram: conic-gradient(from 180deg at 50% 50%, #B8D2F1 0deg, #F289AA 60deg, #C68BF0 106.09deg, #D164DA 153.75deg, #C963E8 221.25deg, #BFC2E8 258.75deg, #FFC999 288.75deg, #D0D8C9 315deg, #BAD0F1 334.13deg, #CED8CB 358.97deg, rgba(255, 255, 255, 0) 360deg);

$brand-colors: (
  ) !default;
$brand-colors: map-merge(('facebook': $facebook,
      'google-plus': $google-plus,
      'twitter': $twitter,
      'linkedin': $linkedin,
      'youtube': $youtube,
      'github': $github,
    ),
    $brand-colors
);

//*-----------------------------------------------
//|   Border
//-----------------------------------------------*/
$border-color: $gray-300 !default;
$border-width: 1px !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-negative-margins: true !default;

//*-----------------------------------------------
//|   Shadow
//-----------------------------------------------*/

$box-shadow: 0 6.25rem 5rem 0 rgba($black, 0.02), 0 4.05063rem 2.928125rem 0 rgba($black, 0.0152), 0 2.4075rem 1.5925rem 0 rgba($black, 0.0121), 0 1.25rem 0.8125rem 0 rgba($black, 0.01), 0 0.50938rem 0.4075rem 0 rgba($black, 0.0079), 0 0.11563rem 0.19687rem 0 rgba($black, 0.0048) !default;
$box-shadow-sm: 0 0.3rem 1rem rgba($black, .09) !default;
$box-shadow-lg: 0 1rem 4rem rgba($black, 0.175) !default;

//*-----------------------------------------------
//|   Spacing
//----------------------------------------------

$spacer: 1rem !default;
$spacers: (
  ) !default;

$spacers: map-merge((0: 0,
      1: $spacer * 0.25,
      2: $spacer * 0.5,
      3: $spacer,
      4: $spacer * 1.8,
      5: $spacer * 2.5,
      6: $spacer * 3,
      7: $spacer * 4,
      8: $spacer * 5,
      9: $spacer * 7.5,
      10: $spacer * 10,
      11: $spacer * 12.5,
      12: $spacer * 15,
    ),
    $spacers
);

//*-----------------------------------------------
//|   Body
//-----------------------------------------------*/
$body-bg: $white !default;
$body-color: $gray-900 !default;

//*-----------------------------------------------
//|   Link
//-----------------------------------------------*/
// Style anchor elements.

$link-color: $gray-1000 !default;
$link-decoration: none !default;
$link-hover-decoration: none !default;
$link-hover-color : $primary;
$link-hover-decoration: underline;

//*-----------------------------------------------
//|   Components
//-----------------------------------------------*/
$border-radius: 1.5rem !default;
$border-radius-sm: 0.625rem !default;
$border-radius-lg: 2.25rem !default;

//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/
$font-family-sans-serif: 'Manrope', sans-serif !default;
$font-family-cursive: 'Manrope', sans-serif !default;
$font-family-monospace: 'Manrope', sans-serif !default;
$font-family-base: 'Manrope', sans-serif !default;

//*-----------------------------------------------
//|   Fonts
//-----------------------------------------------*/
$type-scale: 1.125;
$font-size-base: 1rem !default;
$font-sizes: (
  ) !default;
$font-sizes: map-merge(('-2': 1 / pow($type-scale, 2) * $font-size-base,
      '-1': 1 / $type-scale * $font-size-base,
      0: $font-size-base,
      1: pow($type-scale, 1) * $font-size-base,
      2: pow($type-scale, 2) * $font-size-base,
      3: pow($type-scale, 3) * $font-size-base,
      4: pow($type-scale, 4) * $font-size-base,
      5: pow($type-scale, 5) * $font-size-base,
      6: pow($type-scale, 6) * $font-size-base,
      7: pow($type-scale, 7) * $font-size-base,
      8: pow($type-scale, 8) * $font-size-base,
      9: pow($type-scale, 9) * $font-size-base,
      10: pow($type-scale, 10) * $font-size-base,
      11: pow($type-scale, 11) * $font-size-base,
      12: pow($type-scale, 12) * $font-size-base,
      13: pow($type-scale, 13) * $font-size-base,
      14: pow($type-scale, 12) * $font-size-base,
    ),
    $font-sizes
);


$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.2 !default;
$font-weight-thin: 100 !default;
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-black: 900 !default;

$h1-font-size: map_get($font-sizes, 5) !default;
$h2-font-size: map_get($font-sizes, 4) !default;
$h3-font-size: map_get($font-sizes, 3) !default;
$h4-font-size: map_get($font-sizes, 2) !default;
$h5-font-size: map_get($font-sizes, 1) !default;
$h6-font-size: map_get($font-sizes, '-1') !default;

$headings-font-family: $font-family-sans-serif !default;
$headings-font-weight: $font-weight-semi-bold !default;
$headings-color: $dark-blue !default;

// scss-docs-start display-headings
$display-font-sizes: (
  ) !default;
$display-font-sizes: map-merge((1: map_get($font-sizes, 8),
      2: map_get($font-sizes, 7),
      3: map_get($font-sizes, 6),
      4: map_get($font-sizes, 5),
      5: map_get($font-sizes, 4),
      6: map_get($font-sizes, 3),
    ),
    $display-font-sizes
);

$display-font-weight: $font-weight-black !default;
$display-line-height: 1 !default;
// scss-docs-end display-headings

$lead-font-size: $font-size-lg !default;
$lead-font-weight: $font-weight-normal !default;

$small-font-size: 75% !default;

$text-muted: $gray-500 !default;

$blockquote-font-size: $font-size-lg !default;



//*-----------------------------------------------
//|   Buttons and Forms
//-----------------------------------------------*/
$input-bg : $gray-200 !default;
$input-placeholder-color: $gray-900 !important;
$input-btn-font-size-lg: $font-size-base !default;

$btn-reveal-hover-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
  0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba($black, 0.07),
  0 1px 2px 0 rgba($black, 0.08) !default;
$btn-font-weight: $font-weight-normal !default;
$btn-focus-width: 0 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0.2rem !default;
$btn-border-radius-lg: toRem(10) !default;

$btn-padding-y-lg: 0.8rem;
$btn-padding-x-lg: 1.7rem;
$btn-font-size-lg: 1.125rem;

//*-----------------------------------------------
//|   Navigation
//-----------------------------------------------*/
$navbar-font-size: $font-size-base * 0.8 !default;
$navbar-light-color: $gray-1000 !default;
$navbar-light-hover-color: $primary !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: rgba($black, 0.25) !default;

$navbar-dark-color: rgba($white, .7) !default;
$navbar-dark-hover-color: rgba($white, 0.9) !default;

$navbar-padding-y: map_get($spacers, 2) !default;
$navbar-padding-x: $spacer !default;
$top-nav-height: 4.3125rem !default;
$standard-nav-height: 3.5625rem !default;

$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E"),
    '#',
    '%23'
  ) !default;



// /*-----------------------------------------------
//|   Navbar Brand
//-----------------------------------------------*/
$navbar-brand-font-size: map_get($font-sizes, 3) !default;
$navbar-brand-font-weight: $font-weight-bolder !default;

//*-----------------------------------------------
//|   Viewport Heights & Widths
//----------------------------------------------

$viewport-heights: (
  ) !default;
$viewport-heights: map-merge((25: 25vh,
      50: 50vh,
      75: 75vh,
      100: 100vh,
    ),
    $viewport-heights
);

$viewport-widths: (
  ) !default;
$viewport-widths: map-merge((25: 25vw,
      50: 50vw,
      75: 75vw,
      100: 100vw,
    ),
    $viewport-widths
);

$sizes: (
  ) !default;
$sizes: map-merge((25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto,
    ),
    $sizes
);

// /*-----------------------------------------------
//|   Spinner
//-----------------------------------------------*/
$hr-color: $border-color !default;
$hr-opacity: 1 !default;

//*-----------------------------------------------
//|   card
//-----------------------------------------------*/
// $card-border-radius:                2.5rem !default;
$card-cap-bg: tint-color($gray-500, 90%) !default;


//*-----------------------------------------------
//|   carousel
//-----------------------------------------------*/
$carousel-control-opacity: 0.9 !default;
$carousel-control-hover-opacity: 0.9 !default;
$carousel-control-color: $primary !default;

$carousel-indicator-active-bg: $primary !default;
$carousel-indicator-width: 6px !default;
$carousel-indicator-height: 6px !default;
$carousel-indicator-hit-area-height : 0 !default;
$carousel-control-bg-color: rgba($primary, .3) !important;

$carousel-control-icon-width: 1.8rem !default;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$carousel-control-color}' class='bi bi-arrow-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'/%3E%3C/svg%3E") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$carousel-control-color}' class='bi bi-arrow-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3E%3C/svg%3E") !default;

// Navs
$nav-tabs-link-active-color: $orange !default;
$nav-tabs-link-active-border-color: transparent !default;
$nav-tabs-link-active-bg: rgba($orange, 0.15) !default;


// Accordion
$accordion-padding-y: 1.5rem !default;
$accordion-padding-x: 2.6rem !default;
$accordion-bg: $gray-100 !default;
$accordion-border-width: $border-width !default;
$accordion-border-color: rgba($white, .125) !default;
$accordion-border-radius: 0 !default;

$accordion-button-active-bg: $gray-100 !default;
$accordion-button-active-color: $dark !default;

$accordion-button-focus-border-color: $white !default;
$accordion-button-focus-box-shadow: $light !default;

$accordion-icon-width: 1.8rem !default;
$accordion-icon-active-color: $dark !default;

$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#EE4D47' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E") !default;
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#EE4D47' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E") !default;

//*-----------------------------------------------
//|   progress bar
//-----------------------------------------------*/

$progress-border-radius: toRem(30) !default;
$progress-bar-bg: #8A79DF;