// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.bg-blue {
  background-color: #1160c1 !important;
}

.bg-teal {
  background-color: #007a80 !important;
}

.bg-eggplant {
  background-color: #634158 !important;
}

.btn-sec {
  color: #fff;
  background-color: #DF6951;
  border-color: #DF6951;
}
  
.btn-sec:hover {
  color: #fff;
  background-color:#9c4a39;
  border-color: #9c4a39;
}

.sec-btn-shadow{
  box-shadow: 0 toRem(20) toRem(35) 0 rgba(223, 105, 81, 0.15);
}

.subm-form {
  max-width: 80%;
  display: flex;
  flex-direction: column;
}
  
.subm-form > p {
  text-align: right;
}
  
.subm-form > p > a {
  color: #5E6282;
  font-size: 14px;
}
  
.subm-form-field {
  height: 46px;
  padding: 0 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: 0;
  transition: .2s;
  margin: 2rem;
  width: 90%;
}
  
.subm-form-field:focus {
  border-color: #DF6951;
}

textarea.form-control {
  padding-top: 1rem;
  min-height: calc(1.5em + 6.75rem + 2px);
}

.modal-content > h3 {
  padding-top: 2rem;
  text-align: center;
}

.modal-content.modal-p {
  height: 90px;
}

.subm-btn {
  margin: 1rem auto 2rem auto;
  width: 30%;
  color: #fff;
  background-color: #DF6951;
}

.subm-btn:hover {
  background-color: #9c4a39;
}

.subm-btn:focus {
  color: #fff;
  background-color: #914738;
  border-color: #DF6951;
}

.form-control:focus {
  box-shadow: 0 0 0 0 rgba(241, 165, 1, 0.25);
}

select {
  background-position: right 3rem center;
  height: 46px;
  font-size: 14px;
  padding: 0 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: 0;
  transition: .2s;
  margin: 0 2rem 0 2rem;
  width: 90%;
  color: #5E6282;
  font-size: 14px;
  appearance: none;
  background: url(/assets/img/icons/down.svg) no-repeat right;
  background-position-x: calc(100% - 10px);
}

select:focus{
  background: url(/assets/img/icons/up.svg) no-repeat right;
  background-position-x: calc(100% - 10px);
}

//   option {
//     color: #182dc5;
//   }

select:focus {
  border-color: #DF6951;
}

.partners-carousel {
  right: 0;
  margin-left: 15%;
}

.card-body > img {
  margin: 2rem 2rem 3rem 2rem;
}

.partners-logo > img, .partners-carousel > button {
  transition: .5s;
}

.partners-logo > img:hover, .partners-carousel > button:hover {
  transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -webkit-transform: scale(1.5, 1.5);
  transition: .5s;
}

.dest-card {
  transition: .5s;
}

.dest-card:hover {
  transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  transition: .5s;
}

.card {
  height: 100%;
}

.flickity-prev-next-button.previous {
    left: 0px !important;
}